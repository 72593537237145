$(() => {
  $("#toggle-edit-plmn-form-button").on("click", (e) => {
    $("#edit-plmn-form").toggle();
    e.preventDefault();
  });

  $("#edit-plmn-form").hide();

  cssEnableAfter30s("#sim-image-fetch-form #sim-image-fetch-button");
  cssEnableAfter30s("#sim-image-fetch-form #sim-image-refresh-button");

  applyValidation(
    "#edit-plmn-form #new-plmn-settings",
    "#edit-plmn-form #submit-plmn-settings-button",
    (val) => {
      return isNotEmptyString(val) && hasValidOpIdScope(val);
    },
    (isValid) => {
      if (isValid) {
        $("#edit-plmn-form #plmn-settings-form-error-message").hide();
      } else {
        $("#edit-plmn-form #plmn-settings-form-error-message").show();
      }
    },
  );

  applyValidation(
    "#sim-image-fetch-form #iccid",
    "#sim-image-fetch-form #sim-image-fetch-button",
    isNotEmptyString,
  );

  applyValidation(
    "#sim-image-fetch-form #iccid",
    "#sim-image-fetch-form #sim-image-refresh-button",
    isNotEmptyString,
  );
});

const cssEnableAfter30s = (locator) => {
  const el = $(locator);
  setTimeout(() => {
    if (el.hasClass("css-disable")) {
      return el.removeClass("css-disable");
    }
  }, 30000); //Enable after 30s
};

/**
 * Based on the input of a form field, enable or disable a button based on the result of a validation callback
 * @param {string} inputSelector
 * @param {string} buttonSelector
 * @param {function} validationFnc
 * @param {function} onValidationChange - callback function that is called when the validation result changes
 */
function applyValidation(
  inputSelector,
  buttonSelector,
  validationFnc,
  onValidationChange,
) {
  const input = $(inputSelector);
  const button = $(buttonSelector);
  const disableIfConditionsNotMet = () => {
    const value = input.val();
    if (validationFnc(value)) {
      button.prop("disabled", false);
      if (onValidationChange) {
        onValidationChange(true);
      }
    } else {
      button.prop("disabled", true);
      if (onValidationChange) {
        onValidationChange(false);
      }
    }
  };
  disableIfConditionsNotMet();
  input.on("input", () => {
    disableIfConditionsNotMet();
  });
}

/**
 * Validates input is proper JSON in expected format [{scope: "operator:OP99999"}, {...}, ...].  All objects in must have the same scope of format operator:OP#########
 * @param {*} input
 * @returns
 */
function hasValidOpIdScope(input) {
  const parsedJSON = isValidJSON(input);
  if (!parsedJSON || !Array.isArray(parsedJSON)) {
    return false;
  }
  let firstMatchScope = null;
  return parsedJSON.every((obj) => {
    const scopeVal = validatePlanObject(obj);
    if (!scopeVal || !scopeOpIdMatchesPageSelectedOpId(scopeVal)) {
      return false;
    } else {
      firstMatchScope = firstMatchScope || scopeVal;
      return firstMatchScope === scopeVal;
    }
  });
}

function scopeOpIdMatchesPageSelectedOpId(scopeOpId) {
  const pageOpId = $("#plmn-settings-operator-id").text();
  return scopeOpId === `operator:${pageOpId}`;
}

/**
 *
 * @param {*} jsonString
 * @returns Object if jsonString is valid JSON, false otherwise
 */
function isValidJSON(jsonString) {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    return false;
  }
}

function isNotEmptyString(val) {
  return typeof val === "string" && val.length > 0;
}

/**
 * Validates that object is an object with a 'scope' property that matches the pattern 'operator:OP\d+'
 * @param {*} obj
 * @returns
 */
function validatePlanObject(obj) {
  // Check if obj is an object
  if (typeof obj !== "object" || obj === null || Array.isArray(obj)) {
    return false;
  }

  // Check if obj has a property called 'scope'
  if (!obj.hasOwnProperty("scope")) {
    return false;
  }

  // Check if 'scope' property is a string matching the pattern
  const scopePattern = /^operator:OP\d+$/;
  if (typeof obj.scope !== "string" || !scopePattern.test(obj.scope)) {
    return false;
  }

  // If all conditions are satisfied, return the 'scope' property value
  return obj.scope;
}
